import React, {useEffect} from 'react'
import {Affix, Card, Col, Row, Skeleton, Space, Tag} from 'antd'
import ReplyBar from '@/components/ReplyBar'
import HotSearchPanel from '@/components/HotSearchPanel'
import RelativeAnswerPanel from '@/components/RelativeAnswerPanel'
import ReplyListHeader from "@/components/ReplyListHeader";
import styles from './style.module.less'
import UserAgreementPanel from "@/components/UserAgreementPanel";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchTopicDetail, resetDetail} from "@/features/Topic";
import DocumentTitle from 'react-document-title'
import AuthorPanel from "@/components/AuthorPanel";
import AuthorExpanded from "@/components/AuthorExpanded";
import ErrorCode from "@/utils/ErrorCode";

function TopicDetailPage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {detail: topic, loading} = useSelector(state => state.topic);

    let {id} = useParams();

    const fetchTopic = async (id) => {
        try {
            await dispatch(fetchTopicDetail(id));
        } catch (e) {
            const { code = 0 } = e;
            switch (code) {
                case ErrorCode.NOT_FOUND: {
                    navigate("/404")
                    break;
                }
                default: {
                    console.error(e.message);
                }
            }
        }
    }

    useEffect(() => {
        if (topic.id !== +id) {
            fetchTopic(id);
        }
        return () => dispatch(resetDetail());
    }, [id]);

    const { creator = { isFollowed: false }, createdAt } = topic;

    const { isFollowed } = creator;


    const categories = topic.categories ?? [];
    const replyList = topic.replyList ?? [];

    return (
        <div className={styles.container}>
            <Row gutter={16}>
                <Col span={16}>
                    {loading ? (<Card><Skeleton active/></Card>) : (<DocumentTitle title={topic.title ? `${topic.title} - 答知新`: '答知新'}>
                        <div>
                            <Card>
                                <Space>
                                    {categories.map((category) =>
                                        (<Tag key={category.id}>#{category.name}</Tag>)
                                    )}
                                </Space>
                                <article>
                                    <header className={styles.title}>
                                        {topic.title}
                                    </header>
                                    <AuthorExpanded className={styles.author}
                                                    author={creator}
                                                    time={createdAt}
                                                    showFollow={true}
                                                    isFollowed={isFollowed}
                                                    timeLabel="发布于"/>
                                    <main
                                        className="detail-content"
                                        dangerouslySetInnerHTML={{
                                            __html: topic.content,
                                        }}
                                    />
                                </article>

                                <div className={styles.contentBottom}>
                                    <div className={styles.contentMetaInfo}>
                                        答知新·{topic.replies} 个回答...
                                    </div>
                                    <div className="spacer"/>
                                    <a><img src={require("@/images/invite.png")} alt="去提问"/></a>
                                    <a><img src={require("@/images/answer2.png")} alt="去晒晒"/></a>
                                </div>
                            </Card>

                            <ReplyListHeader replies={topic.replies} display="回答"/>
                            <Space direction="vertical" style={{width: "100%"}}>
                                {replyList.map((reply) => (
                                    <ReplyBar
                                        key={reply.id}
                                        topic={topic}
                                        reply={reply}
                                        replier={reply.replier}
                                        replyList={reply.replyList}
                                        displayList={false}
                                    />
                                ))}
                            </Space>
                        </div>
                    </DocumentTitle>)}
                </Col>
                <Col span={8}>
                    <Affix offsetTop={72}>
                        <Space direction={"vertical"} style={{width: "100%"}}>
                            {loading ? (<Skeleton active className={styles.skeleton} style={{height: "280px"}}/>) : (
                                <AuthorPanel avatar={creator.avatar}
                                             followingCount={creator.followingCount}
                                             followersCount={creator.followersCount}
                                             nickname={creator.nickname}
                                             intro={creator.intro}

                                />)}
                            <HotSearchPanel/>
                            <RelativeAnswerPanel/>
                            <UserAgreementPanel/>
                        </Space>
                    </Affix>
                </Col>
            </Row>
        </div>
    )
}

export default TopicDetailPage
