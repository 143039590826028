import React, {useEffect, useState} from 'react'
import {Space, Spin} from 'antd';
import InfiniteScroll from "react-infinite-scroll-component";
import ShowItem from "@/components/ShowItem";
import * as Topic from '@/services/api/Topic'
import _ from 'lodash';
import styles from './styles.module.less'

function ShowList() {
    const [page, setPage] = useState(1);
    const [topics, setTopics] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [partitions, setPartitions] = useState({});

    const getTopicList = async (page) => {
        let res = await Topic.list(4, page);
        const {data: { list }} = res;
        if (list.length === 0) {
            setHasMore(false);
        }
        let currentTopics;

        if (page === 1) {
            currentTopics = list;
        } else {
            currentTopics = topics.concat(list)
        }

        let n = 5;
        let f = Math.ceil(currentTopics.length / n);
        let partitions = _.range(n).map(i => currentTopics.slice(i * f, (i + 1) * f));

        setPage(page);
        setTopics(currentTopics);
        setPartitions(partitions);
    };

    useEffect(() => {
        getTopicList(1);
    }, [])

    return (
        <InfiniteScroll
            dataLength={topics.length}
            next={() => (getTopicList(page + 1))}
            hasMore={hasMore}
            loader={<Spin className="loader" spinning/>}
            endMessage={
                <p style={{textAlign: "center"}}>
                    <b>Yay! You have seen it all</b>
                </p>
            }
        >
            <Space className={styles.flowContainer} align={"start"}>
                {_.map(partitions, (item, index) => (
                    <Space direction="vertical" key={index} className={styles.flowItem}>
                        {item.map(topic => (<ShowItem key={topic.id} topic={topic}/>))}
                    </Space>
                ))}
            </Space>
        </InfiniteScroll>
    )
}

export default ShowList;
