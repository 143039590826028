import axios from "axios";

export async function list(type, page = 1) {
    const {data} = await axios.post('/topic/list', {
        type, page
    })
    return data;
}

export async function userList(type, uid, page = 1) {
    const {data} = await axios.post(`/user/topic/${type}`, {
        page, "queryUserId": uid,
    })
    return data;
}

export async function toggleCollect(topicId) {
    const { data } = await axios.post(`/user/topic/collect`, {
        topicId
    });
    return data;
}

export async function toggleLike(topicId, replyId) {
    const { data } = await axios.post(`/user/topic/like`, {
        topicId, replyId
    });
    return data;
}

export async function detail(id) {
    const {data} = await axios.post('/topic/details', {id});
    return data;
}

export async function hot() {
    const {data} = await axios.post('/topic/hot', {});
    return data;
}

export async function create(title, content, contentStruct, categories) {
    const {data} = await axios.post('/user/topic/create', {
        title, content, contentStruct, categories
    })
    return data;
}

export async function categoryList() {
    const {data} = await axios.post('/topic/category/list', {});
    return data;
}
