import React, {Component} from 'react'
import {Card} from 'antd';
import {Link} from 'react-router-dom';
import styles from './style.module.less'

export default class UserAgreementPanel extends Component {
    render() {
        return (
            <Card>
                <div className={styles.tab}>
                    <div className='userAgrementItem'><Link className='' to="/"><span
                        className='sourcehansanscn-medium-celeste-21px'>用户协议</span></Link></div>
                    <div className='userAgrementItem'><Link className='' to="/"><span
                        className='sourcehansanscn-medium-celeste-21px'>意见反馈</span></Link></div>
                    <div className='userAgrementItem'><Link className='' to="/"><span
                        className='sourcehansanscn-medium-celeste-21px'>隐私条款</span></Link></div>
                </div>

                <div className={styles.metaInfo}>
                    <img alt="答知新公众号二维码" src={require("@/images/xxbQrcode.jpeg")}/>
                    <div className={styles.contact}>
                        加微信knewzie<br/>
                        或发送邮件至<br/>
                        support@knewzie.com<br/>
                        与答知新的客服取得联系<br/>
                    </div>
                </div>
            </Card>
        )
    }
}
