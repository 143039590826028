import {Card, Carousel, Col, Row, Skeleton, Space, Tag} from "antd";
import {useParams} from "react-router-dom";
import ReplyBar from "@/components/ReplyBar";
import HotSearchPanel from "@/components/HotSearchPanel";
import UserAgreementPanel from "@/components/UserAgreementPanel";
import React, {useEffect} from "react";
import styles from './styles.module.less'
import AuthorExpanded from "@/components/AuthorExpanded";
import ReplyListHeader from "@/components/ReplyListHeader";
import UserContentActionToolbar from "@/components/UserContentActionToolbar";
import AuthorPanel from "@/components/AuthorPanel";
import {useDispatch, useSelector} from "react-redux";
import {fetchTopicDetail, resetDetail} from "@/features/Topic";
import DocumentTitle from "react-document-title";

function ShowDetailPage() {

    const dispatch = useDispatch();
    const {detail: topic, loading} = useSelector(state => state.topic);

    let {id} = useParams();

    const fetchIfNeed = async () => {
        if (topic.id !== +id) {
            try {
                dispatch(fetchTopicDetail(id));
            } catch (e) {
                console.error(e.message);
            }
        }
    };

    useEffect(() => {
        fetchIfNeed();
        return () => {
            dispatch(resetDetail());
        }
    }, [id]);

    const RenderContent = ({topic}) => {
        const {
            categories = [],
            videoList = [], imageList = [],
            replyList = [],
            creator = {}, createdAt
        } = topic;
        const showVideo = videoList.length > 0;

        return (<>
            <DocumentTitle title={topic.title ? `${topic.title} - 答知新` : '答知新'}>
                <Card>
                    <Space>
                        {categories.map((category) => (
                            <Tag key={category.id}> #{category.name}</Tag>
                        ))}
                    </Space>
                    <div className={styles.title}> {topic.title} </div>
                    <div>
                        <AuthorExpanded className={styles.author} author={creator} time={createdAt}
                                        timeLabel="发布于"/>
                    </div>
                    {showVideo ?
                        (<video
                            className='show_banner_video'
                            playsInline
                            controls={true}
                            src={videoList[0]}
                        >
                        </video>) : (<Carousel autoplay autoplaySpeed={5000}>
                            {
                                imageList
                                    .map((banner, idx) =>
                                        (<div key={idx} className={styles.bannerContainer}>
                                            <div>
                                                <img className={styles.banner} src={banner}/>
                                            </div>
                                        </div>))
                            }
                        </Carousel>)}
                    <div className={styles.content}
                         dangerouslySetInnerHTML={{
                             __html: topic.content,
                         }}
                    />
                    <UserContentActionToolbar topic={topic}/>
                </Card>
            </DocumentTitle>
            <ReplyListHeader replies={topic.replies} display="评论"/>

            <Space direction="vertical" style={{width: "100%"}}>
                {replyList.map((reply) => (
                    <ReplyBar
                        key={reply.id}
                        topic={topic}
                        reply={reply}
                        replier={reply.replier}
                        replyList={reply.replyList}
                        displayList={false}
                    />
                ))}
            </Space>
        </>)
    }

    const {creator = { isFollowed: false }} = topic
    const { isFollowed } = creator;

    return (
        <div>
            <div className={styles.container}>
                <Row gutter={16}>
                    <Col span={16}>
                        {loading ?
                            (<Skeleton active className={styles.skeleton}/>) :
                            (<RenderContent topic={topic}/>)
                        }
                    </Col>
                    <Col span={8}>
                        <Space direction="vertical" style={{width: "100%"}}>
                            {loading ? (<Skeleton active className={styles.skeleton} style={{height: "280px"}}/>) : (
                                <AuthorPanel avatar={creator.avatar}
                                             nickname={creator.nickname}
                                             intro={creator.intro}
                                             showFollow={true}
                                             isFollowed={isFollowed}

                                />)}
                            <HotSearchPanel/>
                            <UserAgreementPanel/>
                        </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ShowDetailPage;
