import {Carousel, Col, Row, Space} from "antd";
import React from "react";
import styles from './styles.module.less'
import {Link} from "react-router-dom";

function DownloadCarousel() {
    return (
        <Carousel autoplay autoplaySpeed={7000} >
            <div>
                <Row className={styles.pageContainer}>
                    <Col>
                        <div className={styles.title}>
                            <img alt="titleImg"
                                 src={require("@/images/homepageTitle1.png")}/>
                        </div>
                        <div className={styles.intro}>
                            <div>新西兰首个服务华人的问答平台</div>
                            <div>在这里，你即是提问者，也是回答者。</div>
                            <div>你有疑惑，大家来答，你的回答，为大家解忧。</div>
                            <div>回答一个问题，让更多人听到你的声音！</div>
                        </div>

                        <Space size={"large"}>
                            <a href="https://api.knewzie.com/download/apk">
                                <img alt="安卓下载" src={require("@/images/android_download.png")}/>
                            </a>
                            <a href="https://apps.apple.com/nz/app/%E7%AD%94%E7%9F%A5%E6%96%B0/id1551768968">
                                <img alt="iOS下载" src={require("@/images/iOS_download.png")}/>
                            </a>
                        </Space>
                    </Col>
                    <div className="spacer"/>
                    <Col>
                        <img className={styles.logo} alt="" src={require("@/images/homepage-01@1x.png")}/>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className={styles.pageContainer}>
                    <Col>
                        <div className={styles.title}>
                            <img alt="titleImg"
                                 src={require("@/images/homepageTitle2.png")}/>
                        </div>
                        <div className={styles.intro}>
                            <div>在这里，用户即是提问者，也是回答者。</div>
                            <div>通过问答的形式。</div>
                            <div>最快速聚集人气和流量。</div>
                        </div>
                        <Link to="/"><img alt="进入答知新"
                                          src={require("@/images/intoKnewzie.png")}
                                          className=''/></Link>
                    </Col>
                    <div className="spacer"/>
                    <Col>
                        <img className={styles.logo} alt="" src={require("@/images/homepage-02@1x.png")}/>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className={styles.pageContainer}>
                    <Col>
                        <div className={styles.title}>
                            <img alt="titleImg"
                                 src={require("@/images/homepageTitle3.png")}/>
                        </div>
                        <div className={styles.intro}>
                            <div>这里汇聚新西兰华社最有影响力、话语权、学识的知识</div>
                            <div>分享者，在KOL达人经济火爆的当下，用户在获取第一</div>
                            <div>手行业资讯专业知识的同时，商家也可以通过这一模式</div>
                            <div>最高效传递商业价值。</div>
                        </div>
                        <Link to="/"><img alt="进入答知新"
                                          src={require("@/images/intoKnewzie.png")}
                                          className=''/></Link>
                    </Col>
                    <div className="spacer"/>
                    <Col>
                        <img className={styles.logo} alt="" src={require("@/images/homepage-03@1x.png")}/>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className={styles.pageContainer}>
                    <Col>
                        <div className={styles.title}>
                            <img alt="titleImg"
                                 src={require("@/images/homepageTitle4.png")}/>
                        </div>
                        <div className={styles.intro}>
                            <div>新西兰知多少？</div>
                            <div>小知识一网打尽</div>
                        </div>
                        <Link to="/"><img alt="进入答知新"
                                          src={require("@/images/intoKnewzie.png")}
                                          className=''/></Link>
                    </Col>
                    <div className="spacer"/>
                    <Col>
                        <img className={styles.logo} alt="" src={require("@/images/homepage-04@1x.png")}/>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className={styles.pageContainer}>
                    <Col>
                        <div className={styles.title}>
                            <img alt="titleImg"
                                 src={require("@/images/homepageTitle5.png")} />
                        </div>
                        <div className={styles.intro}>
                            <div>鼓励用户通过晒图的形式，分享新西兰生活的方方面面</div>
                            <div>譬如品牌产品、网红餐厅、主题活动等，很适合多种商</div>
                            <div>业形态的软植入。</div>
                        </div>
                        <Link to="/"><img alt="进入答知新"
                                          src={require("@/images/intoKnewzie.png")}
                                          className=''/></Link>
                    </Col>
                    <Col>
                        <img className={styles.logo} alt="" src={require("@/images/homepage-05@1x.png")}/>
                    </Col>
                </Row>
            </div>
        </Carousel>
    )
}

export default DownloadCarousel;
