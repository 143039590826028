import {Col, Row, Space} from "antd";
import MyBar from "../../components/MyPanel";
import HotSearchPanel from "../../components/HotSearchPanel";
import HelpOtherPanel from "../../components/HelpOtherPanel";
import UserAgreementPanel from "../../components/UserAgreementPanel";
import React from "react";
import styles from "./style.module.less";

function MainLayout({ children }) {
    return (<div className={styles.container}>
        <Row gutter={16}>
            <Col span={16}>
                {children}
            </Col>
            <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                    <MyBar/>
                    <HotSearchPanel/>
                    <HelpOtherPanel/>
                    <UserAgreementPanel/>
                </Space>
            </Col>
        </Row>
    </div>)
}

export default MainLayout;
