import { createSlice } from '@reduxjs/toolkit'
import * as Topic from "@/services/api/Topic";

const hotSearchSlice = createSlice({
    name: 'hotSearch',
    initialState: {
        topics: [],
        loading: false,
        loaded: false,
    },
    reducers: {
        reloadTopics: (state, { payload }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.topics = payload;
            state.loaded = true;
        },
        changeLoadState: (state, { payload }) => {
            state.loading = payload;
        }
    },
})

const { changeLoadState, reloadTopics } = hotSearchSlice.actions;

// Action creators are generated for each case reducer function
export const reload =  () => async (dispatch) => {
    try {
        dispatch(changeLoadState(true));
        let response = await Topic.hot();
        const {data: list } = response;

        dispatch(reloadTopics(list));
    } catch (e) {

    } finally {
        dispatch(changeLoadState(false));
    }

}
export default hotSearchSlice.reducer
