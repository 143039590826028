import * as React from 'react'
import {DecoratorBlockNode, SerializedDecoratorBlockNode} from "@lexical/react/LexicalDecoratorBlockNode";
import {DOMExportOutput, EditorConfig, ElementFormatType, LexicalEditor, NodeKey, Spread} from "lexical";
import {BlockWithAlignableContents} from "@lexical/react/LexicalBlockWithAlignableContents";

export type SerializedLinkCardNode = Spread<
    {
        src: string;
        title: string;
        desc: string;
        icon: string;
        type: 'linkcard';
        version: 1;
    },
    SerializedDecoratorBlockNode
    >;

export interface LinkCardPayload {
    key?: NodeKey;
    src: string;
    title: string;
    desc: string;
    icon: string;
}

type LinkCardComponentProps = Readonly<{
    className: Readonly<{
        base: string;
        focus: string;
    }>;
    format: ElementFormatType | null;
    nodeKey: NodeKey;
    src: string;
    title: string;
    desc: string;
    icon: string;
}>;


function LinkCardComponent({
                              className,
                              format,
                              nodeKey,
                              src, title, desc, icon
                          }: LinkCardComponentProps) {
    return (
        <BlockWithAlignableContents
            className={className}
            format={format}
            nodeKey={nodeKey}>

            <a href={src} className="link-card" target="_blank">
                <img className="icon" src={icon} alt="Link Card Icon" />
                <div className="card-body">
                    <header>{title}</header>
                    <p>{desc}</p>
                </div>
            </a>

        </BlockWithAlignableContents>
    );
}

export default class LinkCardNode extends DecoratorBlockNode {

    __src: string;
    __title: string;
    __desc: string;
    __icon: string;

    static getType(): string {
        return 'linkcard';
    }

    static clone(node: LinkCardNode): LinkCardNode {
        return new LinkCardNode(node.__src, node.__title, node.__desc, node.__icon, node.__format, node.__key);
    }

    constructor(src: string, title: string, desc: string, icon: string, format?: ElementFormatType, key?: NodeKey ) {
        super(format, key);
        this.__src = src;
        this.__title = title;
        this.__desc = desc;
        this.__icon = icon;
    }

    exportDOM(editor: LexicalEditor): DOMExportOutput {
        const container = document.createElement("div");
        container.style.textAlign = this.__format;
        const a = document.createElement("a");
        a.href = this.__src;
        a.className = "link-card"
        a.target = "_blank"

        const logo = document.createElement("img");
        logo.className = "icon"
        logo.alt = "Link Card Icon";
        logo.src = this.__icon;
        a.appendChild(logo);

        const contentContainer = document.createElement("div");
        contentContainer.className = "card-body"
        a.appendChild(contentContainer);

        const title = document.createElement("header");
        title.innerText = this.__title;
        contentContainer.appendChild(title);

        const desc = document.createElement("p");
        desc.innerText = this.__desc;
        contentContainer.appendChild(desc)

        container.appendChild(a);
        return { element: container };
    }

    updateDOM(): false {
        return false;
    }

    static importJSON(serializedNode: SerializedLinkCardNode): LinkCardNode {
        const node = $createLinkCardNode(serializedNode.src, serializedNode.title, serializedNode.desc, serializedNode.icon);
        node.setFormat(serializedNode.format);
        return node;
    }

    exportJSON(): SerializedLinkCardNode {
        return {
            ...super.exportJSON(),
            type: 'linkcard',
            version: 1,
            src: this.__src,
            title: this.__title,
            desc: this.__desc,
            icon: this.__icon
        };
    }

    isTopLevel(): true {
        return true;
    }

    decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || '',
        };
        return (
            <LinkCardComponent
                className={className}
                format={this.__format}
                nodeKey={this.getKey()}
                src={this.__src}
                title={this.__title}
                desc={this.__desc}
                icon={this.__icon}
            />
        );
    }
}

export function $createLinkCardNode(src: string, title: string, desc: string, icon: string): LinkCardNode {
    return new LinkCardNode(src, title, desc, icon, 'center');
}
