import React from "react";

import styles from './style.module.less'

/**
 * 回答列表里面的作者信息
 * @param replier
 * @returns {JSX.Element}
 */
export default function ({ replier }) {

    let intro = (<div/>)
    if (replier.intro) {
        intro = (<div><span>&nbsp;·&nbsp;</span><span className={styles.intro}>{replier.intro}</span></div>)
    }

    return (<div className={styles.container}>
        <img
            src={replier.avatar}
            className="answer_banner_headimg"
            alt="头像"
        />
        <div className={styles.nickname}>{replier.nickname}</div>
        {intro}
    </div>);
}
