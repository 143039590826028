import React from 'react';
import {Space} from 'antd';
import TopicList from "@/layout/TopicList";
import FollowingUserPanel from "../../components/FollowingUserPanel";
import TagBar from "../../components/TagBar";

function Following() {
    return (
        <Space style={{width: "100%"}} direction="vertical">
            <FollowingUserPanel/>
            <TagBar/>
            <TopicList type='1'/>
        </Space>
    )
}

export default Following;
