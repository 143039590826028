import nprogress from 'nprogress';
import React, {useEffect} from 'react';

function Progress ({ children }) {
    if (typeof window !== 'undefined') {
        nprogress.start();
    }
    useEffect(() => {
        nprogress.done();
    }, [])
    return (<>{children}</>);
}

export default Progress;
