import {EditorConfig, LexicalNode, NodeKey, SerializedTextNode, Spread, TextNode} from "lexical";
import {addClassNamesToElement} from "@lexical/utils";


export class IssueNode extends TextNode {
    static getType(): string {
        return 'issue';
    }

    constructor(
        text: string,
        key?: NodeKey,
    ) {
        super(text, key);
    }

    createDOM(config: EditorConfig): HTMLElement {
        const content = this.__text.slice(1).trim();
        const element = document.createElement("a");
        addClassNamesToElement(element, config.theme.hashtag);
        element.innerText = this.__text;
        element.setAttribute("data-issue", content);
        element.href = `/issue/${encodeURIComponent(content)}`
        return element;
    }

    static clone(node: IssueNode): IssueNode {
        return new IssueNode(node.__text, node.__key);
    }

    exportJSON(): SerializedTextNode {
        return {
            ...super.exportJSON(),
            type: 'issue',
        };
    }

    static importJSON(serializedNode: SerializedTextNode): IssueNode {
        const node = $createIssueNode(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }

    canInsertTextBefore(): boolean {
        return false;
    }

    canInsertTextAfter(): boolean {
        return !this.isComposing();
    }

    isTextEntity(): true {
        return true;
    }

    setTextContent(text: string): this {
        super.setTextContent(text);
        //next select() is hack for LexicalText textNodeTransform in registerLexicalTextEntity
        this.select();
        return this;
    }
}


export function $createIssueNode(text = ''): IssueNode {
    return new IssueNode(text);
}

export function $isLinkHashtagNode(
    node: LexicalNode | null | undefined,
): node is IssueNode {
    return node instanceof IssueNode;
}
