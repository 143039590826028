import { createSlice } from '@reduxjs/toolkit'
import * as User from "@/services/api/User";
import {message} from "antd";

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: undefined,
        following: [],
        loaded: false,
        loading: false
    },
    reducers: {
        fetchedUserInfo: (state, { payload }) => {
            state.user = payload;
            state.loaded = true;
        },
        fetchedFollowing: (state, { payload }) => {
            state.following = payload;
        },
        changeLoadState: (state, { payload }) => {
            state.loading = payload;
        }
    }
})

const { fetchedUserInfo, fetchedFollowing, changeLoadState } = userSlice.actions;

export const fetchFollowing = () => async (dispatch, getState) => {
    const { user: { user } } = getState();
    if (!user) {
        return;
    }
    try {
        const { uid } = user;
        let res = await User.following(uid);
        const { success } = res;
        if (!success) {
            return;
        }
        const { data : {list}} = res;
        dispatch(fetchedFollowing(list))
    } catch (e) {

    }
}

const afterLogin = (user) => (dispatch) => {
    dispatch(fetchedUserInfo(user));
    dispatch(fetchFollowing())
}

export const loadUserInfo = () => async (dispatch) => {
    try {
        dispatch(changeLoadState(true));
        let res = await User.me();
        const { success } = res;
        if (success) {
            const user = res.data;
            dispatch(afterLogin(user));
        }
    } catch (e) {
    } finally {
        dispatch(changeLoadState(false));
    }
}

export const login = (platformType, platformId, password, key = '') => async (dispatch) => {
    try {
        dispatch(changeLoadState(true));
        let response = await User.login(platformType, platformId, password, key);
        const {success} = response;

        if (!success) {
            message.error(response.message);
        } else {
            const {data: {user}} = response;
            dispatch(afterLogin(user));
        }
    } catch (e) {
        message.error(e);
    } finally {
        dispatch(changeLoadState(false));
    }
}

export default userSlice.reducer;
