import React from "react";
import styles from './styles.module.less'
import {MessageFilled, TagsFilled} from "@ant-design/icons";
import {Space} from "antd";
import LikeButton from "@/components/LikeButton";
import CollectButton from "@/components/CollectButton";

/**
 * 内容下面的工具栏
 * @returns {JSX.Element}
 */
function UserContentActionToolbar ({ topic, showCollect = true, replyId = 0 }) {
    let { id: topicId, replies } = topic;

    return (<Space className={styles.container}>
        <LikeButton {...topic} topicId={topicId} replyId={replyId} />
        <a href="#" onClick={(e) => e.preventDefault()}>
            <MessageFilled />
            <span> {replies} </span>
        </a>
        { showCollect && <CollectButton {...topic} /> }
    </Space>)
}

export default UserContentActionToolbar;
