import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Card, Skeleton, Space, message, Spin} from "antd";
import styles from "@/layout/TopicList/styles.module.less";
import InfiniteScroll from "react-infinite-scroll-component";
import TopicListItem from "@/components/TopicListItem";
import * as Topic from "@/services/api/Topic";

function UserTopicList({ type }) {

    const { user } = useSelector(state => state.user)
    const navigate = useNavigate();

    const [topics, setTopics] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        loadData(1);
    }, [type])

    const loadData = async (page) => {
        if (user === undefined) {
            // 未登录
            navigate("/login", { replace: true });
            return;
        }

        try {
            const { uid } = user;
            const res = await Topic.userList(type, uid, page);

            const { data: { list }} = res;

            if (page === 1) {
                setTopics(list);
            } else {
                const currentTopics = topics.concat(list);
                setTopics(currentTopics);
            }

            setHasMore(list.length === 0);

            setInitialized(true);
            setPage(page + 1);
        } catch (e) {
            message.error("获取列表失败");
        } finally {

        }
    }

    let skeleton = (<>
        <Skeleton active className={styles.skeleton}/>
        <Skeleton active className={styles.skeleton}/>
        <Skeleton active className={styles.skeleton}/>
    </>)


    return (
        <InfiniteScroll
            dataLength={topics.length}
            next={() => {
                loadData(page + 1);
            }}
            hasMore={hasMore}
            loader={<Spin className="loader" spinning />}
            endMessage={
                <p style={{textAlign: "center"}}>
                    <b>Yay! You have seen it all</b>
                </p>
            }
        >
            <Space direction="vertical" style={{width: "100%"}}>
                {
                    initialized ? (topics.map((topic) => (
                        <Card key={topic.id}>
                            <TopicListItem topic={topic}/>
                        </Card>
                    ))) : (skeleton)
                }
            </Space>
        </InfiniteScroll>);
}

export default UserTopicList;
