import React, {Component} from 'react'
import {Carousel, Skeleton} from 'antd';
import axios from 'axios';
import BannerItem from "@/components/Home/BannerItem";
import styles from './style.module.less'

export default class Banner extends Component {

    state = {
        delaySeconds: 3000,
        banners: []
    }

    componentDidMount() {
        let t = this;
        axios.post('/config/banner/list', {"type": "banner_home_recommend"})
            .then(function (response) {
                if (response.status === 200 && response.data.code === 200) {
                    const delaySec = response.data.data.delaySeconds;
                    const list = response.data.data.list;
                    //console.log(list);
                    t.setState({
                        delaySeconds: delaySec * 1000,
                        banners: list
                    });
                }
            })
    }

    render() {
        const {banners} = this.state;
        return (<Skeleton className={styles.skeleton} loading={banners.length === 0}>
            <Carousel className={styles.container} autoplay autoplaySpeed={this.state.delaySeconds}>
                {
                    banners.map((banner, id) => (
                        <BannerItem key={id} banner={banner}/>
                    ))
                }
            </Carousel>
        </Skeleton>)
    }
}



