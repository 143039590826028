import styles from './style.module.less'
import Avatar from "../Avatar";
import React from 'react'
import {Card, Col, Divider, Row, Space} from "antd";

const AuthorPanel = ({avatar, nickname, intro, followingCount, followersCount, title}) => {
    return (<Card className={styles.container}>
        <Row gutter={16} className={styles.metaInfo}>
            <Col>
                <Avatar url={avatar}/>
            </Col>
            <Col className={styles.metaInfoTextContainer}>
                <div className={styles.nickname}>{nickname}</div>
                <div className={styles.title}>{intro}</div>
            </Col>
        </Row>
        <div>暂无简介</div>
        <Divider/>
        <Space split={<Divider type="vertical"/>} className={styles.relationship}>
            <div>
                <div>{ followingCount ?? 0 }</div>
                <div>关注</div>
            </div>
            <div>
                <div>{ followersCount ?? 0 }</div>
                <div>粉丝</div>
            </div>
        </Space>
        <a className={styles.view}>查看 TA 的个人主页</a>

    </Card>)
};

export default AuthorPanel;
