import React, {useEffect} from 'react'
import {Card, Skeleton} from 'antd';
import {SyncOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {reload} from "@/features/HotSearch";
import styles from './style.module.less'
import {Link} from "react-router-dom";

function HotSearchPanel() {

    const { topics, loading, loaded } = useSelector((state) => state.hotSearch)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!loaded) {
            dispatch(reload())
        }
    }, []);


    return (
        <Card className={styles.container}>
            <section className={styles.tabContainer}>
                <span className={styles.tabTitle}>知新热搜</span>
                <div className="spacer"/>
                <a className={styles.refreshControl} href="#" onClick={() => (dispatch(reload()))}>
                    <SyncOutlined spin={loading} /> <span>点击刷新</span>
                </a>
            </section>
            <Skeleton active loading={loading}>
                <ol className={styles.hotList}>
                    {
                        topics.map((hot, idx) =>
                            (<li key={idx} className={styles.topRank}><Link to={`/topic/${hot.id}`}>{hot.title}</Link></li>)
                        )
                    }
                </ol>
            </Skeleton>
        </Card>
    )
}

export default HotSearchPanel;
