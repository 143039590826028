import {LoadingOutlined, TagsFilled} from "@ant-design/icons";
import React, {useState} from "react";
import styles from './style.module.less'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {message, Spin} from "antd";
import * as Topic from "@/services/api/Topic";

function CollectButton({isCollect, id}) {

    const [isCollectState, setCollectState] = useState(isCollect);
    const [loading, setLoading] = useState(false);

    const {user} = useSelector(state => state.user);
    const navigate = useNavigate();

    const toggleLike = async (e) => {
        e.preventDefault();
        if (!user) {
            navigate("/login");
        } else {
            try {
                setLoading(true);
                const {success, message} = await Topic.toggleCollect(id);
                if (!success) {
                    throw new Error(message);
                }

                setCollectState(!isCollectState);
            } catch (e) {
                message.error(e);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <Spin spinning={loading} indicator={<LoadingOutlined spin/>}>
            <a href="#" onClick={toggleLike} className={classNames({
                [styles.actions]: true,
                [styles.isCollect]: isCollectState,
            })}>
                <TagsFilled/>
            </a>
        </Spin>
    )
}

CollectButton.propTypes = {
    isCollect: PropTypes.bool,
    topicId: PropTypes.number,
}


export default CollectButton;
