import Avatar from "../Avatar";
import {Col, Row, message} from "antd";
import {duration} from "@/utils/TimeUtil";
import styles from './style.module.less'
import classNames from "classnames";
import React, {useState} from 'react'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as User from '@/services/api/User'

function AuthorExpanded({
                            author, timeLabel, time,
                            className = '',
                            showFollow = false,
                            isFollowed = false
                        }) {

    const { user } = useSelector(state => state.user)
    const navigate = useNavigate();

    const [currentFollowed, setFollowed] = useState(isFollowed);

    const followAction = async (e) => {
        e.preventDefault();
        if (!user) {
            navigate("/login", { replace: true });
            return;
        }
        try {
            const res = await User.follow(author.uid);
            const { success } = res;
            if (success) {
                setFollowed(!currentFollowed);
            }

        } catch (e) {
            message.error(e.message);
        }
    }


    return (
        <Row className={classNames(className, styles.container)}>
            <Col>
                <Avatar url={author.avatar}/>
            </Col>
            <Col>
                <div>
                    <span className={styles.nickname}>{author.nickname}</span>
                    <span className={styles.time}>{timeLabel} · {duration(time)}</span>
                </div>
                <div className={styles.intro}>{author.intro}</div>
            </Col>
            <div className="spacer"/>
            {showFollow && (<a onClick={followAction}>
                <div className={classNames({
                    [styles.followed]: currentFollowed, [styles.follow]: !currentFollowed}
                )}> {currentFollowed ? "已关注" : "关  注"} </div>
            </a>)}
        </Row>
    )
}

export default AuthorExpanded;

