import moment from "moment";

export function duration(time) {
    let createdAt = moment(time * 1000);
    let diff = moment.duration(moment().diff(createdAt));
    if (diff.asDays() > 10) {
        return createdAt.format("YYYY-MM-DD");
    } else if (diff.asHours() >= 24) {
        return `${Math.floor(diff.asDays())} 天前`;
    } else if (diff.asMinutes() >= 60) {
        return `${Math.floor(diff.asHours())} 小时前`;
    } else if (diff.asSeconds() >= 60) {
        return `${Math.floor(diff.asMinutes())} 分钟前`;
    } else if (diff.asSeconds() > 0) {
        return `${Math.floor(diff.asSeconds())} 秒前`;
    } else {
        return "刚刚";
    }
}