import React from 'react'
import {Col, Divider, Row, Space} from 'antd';
import {NavLink, useMatch} from 'react-router-dom';
import UserToolbar from "../UserToolbar";
import styles from './style.module.less'

function NavigationBar() {

    let matchTopic = useMatch("/topic/:id");
    let matchShow = useMatch("/show/:id");

    const matchDetail = matchShow || matchTopic;

    return (
        <Row className={styles.container}>
            {
                (!matchDetail) && (<Col span={8} className={styles.tab}>
                    <Space style={{width: "100%"}} size={'large'} split={<Divider type="vertical"/>}>
                        <NavLink to="/">推荐</NavLink>
                        <NavLink to="/following">关注</NavLink>
                        <NavLink to="/knowledge">涨知识</NavLink>
                        <NavLink to="/show">晒一晒</NavLink>
                    </Space>
                </Col>)
            }
            <Col flex={1}>
                <UserToolbar/>
            </Col>
        </Row>
    );
}

export default NavigationBar;
