import React from "react";
import styles from "./style.module.less"

export default function ({replies = 0, display}) {
    return (
        <div className={styles.container}>
            <span>{replies} 个{display}</span>
            <div className="spacer"></div>
            <img alt="orderByTime" src={require("@/images/orderByTime.png")} className='orderByTime'/>
        </div>
    )
}
