import axios from "axios";

export async function fetchToken(filename: string, contentType: string) {
    const {data} = await axios.post('/aws/token/generate', {filename, contentType});
    return data;
}

export async function uploadFile(url: string, file: File, contentType: string) {
    const { data } = await axios.put(url, file, {
        headers: {
            "content-type": contentType,
        },
    })
    return data;
}

export async function transferFile(url: string) {
    const { data } = await axios.put('/aws/image/transfer', { url });
    return data;
}
