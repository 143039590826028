import {Col, Divider, Row, Space} from 'antd';
import React from 'react';
import {Link} from "react-router-dom";
import styles from './style.module.less'
import KnewzieHomeContentBar from "../../layout/KnewzieHomeContentBar";
import DownloadCarousel from "../../components/DownloadCarousel";

function Download() {

    const DownloadPanel = () => {
        return (<div className='homeDownloadDiv' id="downloadApp">
            <div className="homeDownloadBanner">
                <Row>
                    <Col span={10} offset={2}>
                        <div className="homeDownloadBannerLeft">
                            <div className="homeDownloadQRcodeDiv">
                                <div className="homeDownloadQRcodeDiv1"><img alt="安卓下载" src={require("@/images/download_android.png")} className='homeDownloadQRcode'/></div>
                                <div className="homeDownloadQRcodeDiv2"><img alt="ios下载" src={require("@/images/download_ios.png")} className='homeDownloadQRcode'/></div>
                            </div>
                            <div className='homeDownloadQRcodeBtnDiv'>
                                <div className='homeBannerBtn'><a href="https://api.knewzie.com/download/apk" ><img alt="安卓下载" src={require("@/images/android_download.png")} className=''/></a></div>
                                <div className='homeBannerBtn2'><a href="https://apps.apple.com/nz/app/%E7%AD%94%E7%9F%A5%E6%96%B0/id1551768968" ><img alt="IOS下载" src={require("@/images/iOS_download.png")} className=''/></a></div>
                            </div>
                        </div>
                    </Col>
                    <Col  span={12}>
                        <div className="homeDownloadBannerRight">
                            <div className="homeContentImageDiv"><img alt="" src={require("@/images/homepage_download_1.png")} className='homeDownloadImage'/></div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>)
    }

    return (
        <div className={styles.background}>
            <div className={styles.content} style={{height: 64}}>
                <Link to="/">
                    <img src={require("@/images/logo2.png")} height={48} alt="logo"/>
                </Link>
                <div className="spacer" />
                <Space className={styles.navbar} split={<Divider type="vertical" /> } >
                    <Link to="/">首页</Link>
                    <a href="#">下载App</a>
                    <a href="#">关于答知新</a>
                    <Link to="/">进入答知新</Link>
                    <Link to="/login">登录</Link>
                </Space>
            </div>
            <div>
                <DownloadCarousel/>
                <KnewzieHomeContentBar/>
                <DownloadPanel />
            </div>
        </div>
    );
}

export default Download;
