import React from 'react'
import {Card} from 'antd';
import styles from './style.module.less'
import {SyncOutlined} from "@ant-design/icons";

function RelativeAnswerPanel () {
    return (
        <Card>
            <section className={styles.tabContainer}>
                <div className={styles.tabTitle}>相关问题</div>
                <div className="spacer"/>
                <a className={styles.refreshControl} href="#">
                    <SyncOutlined /> <span>点击刷新</span>
                </a>
            </section>
            <div>
                <div className='topicTitle2'>新西兰投资移民2022年是否有变化，需要准备什么资料？</div>
                <div className='topicAnswer2'>
                    <span className='sourcehansanscn-regular-normal-black-18px'>303个回答</span>
                </div>
            </div>
            <div>
                <div className='topicTitle2'>新西兰多少钱就可以投资移民了？对申请人的英语、年龄有没有要求呀？</div>
                <div className='topicAnswer2'>
                    <span className='sourcehansanscn-regular-normal-black-18px'>303个回答</span>
                </div>
            </div>
            <div>
                <div className='topicTitle2'>有通过投资移民的方式移民来新西兰的大佬吗，可以分享一下经验吗？</div>
                <div className='topicAnswer2'>
                    <span className='sourcehansanscn-regular-normal-black-18px'>303个回答</span>
                </div>
            </div>

        </Card>
    )
}

export default RelativeAnswerPanel;
