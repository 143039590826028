import React from 'react'
import {Link} from 'react-router-dom';
import NavigationBar from "@/components/NavigationBar";
import {Affix, Divider, Space} from "antd";
import styles from './styles.module.less'

function NavigationHeader() {
    return (
        <header className={styles.container}>
            <div className={styles.content}>
                <Link to="/"><img src={require("@/images/logo2.png")} height={48} alt="logo"/></Link>
                <div className="spacer"/>
                <Space className={styles.navbar} split={<Divider type="vertical"/>}>
                    <Link to="/">首页</Link>
                    <Link to="/download">下载App</Link>
                    <Link to="/home">关于答知新</Link>
                </Space>
            </div>
            <Affix>
                <nav style={{backgroundColor: "white"}}>
                    <div className={styles.nav}>
                        <NavigationBar/>
                    </div>
                </nav>
            </Affix>
        </header>
    )
}

export default NavigationHeader;

