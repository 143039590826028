import React, {Component} from 'react'
import {Card, Space} from 'antd';
import {Link} from 'react-router-dom';
import axios from 'axios';

import styles from './style.module.less'
import {useSelector} from "react-redux";

function FollowingUserPanel() {

    const {following} = useSelector(state => state.user)
    const list = following;
    const showPanel = list.length > 0;
    return (
        <>{showPanel && (
            <div className={styles.container}>
                <Space direction="horizontal">
                    {
                        list.filter((user) => user.avatar)
                            .map((user) => (
                                    <div key={user.uid} className={styles.userContainer}>
                                        <div><Link to="/">
                                            <img className={styles.avatar} src={user.avatar}
                                                 alt="头像"/></Link></div>
                                        <div><Link to="/"><span
                                            className={styles.nickname}>{user.nickname}</span></Link></div>
                                    </div>
                                )
                            )
                    }
                </Space>
            </div>)}
        </>
    )
}

export default FollowingUserPanel;
