import React, {Component} from 'react';
import {Tag, Space} from 'antd';
import axios from 'axios';
import styles from './style.module.less'

export default class TagBar extends Component {

    state = {
        categories: []
    }

    getCategoryList() {
        let t = this;
        axios.post('/topic/category/list', {}).then(function (response) {
            if (response.status === 200 && response.data.code === 200) {
                const list = response.data.data;
                // console.log(list);
                t.setState({
                    categories: list
                });
            }
        })
    }

    componentDidMount() {
        this.getCategoryList();
    }

    render() {

        let {categories} = this.state;

        categories.length = Math.min(categories.length, 5);

        return (
            <Space className={styles.container}>
                {categories.map((category) => (<Tag key={category.id}>#{category.name}</Tag>))}
                <Tag color='#f6f6f6'
                     style={{backgroundColor: 'transparent'}}><span
                    className={styles.more}>#更多话题</span></Tag>
            </Space>
        )
    }
}
