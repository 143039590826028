import Avatar from "../Avatar";
import React from "react";
import {Col, Row, Space, Tag} from "antd";
import {Link} from "react-router-dom";
import styles from './style.module.less';

function TopicListItem({
                           topic: {
                               id, title, creator, updatedContent,
                               imageList, videoList,
                               pv, replies, likes, categories
                           }
                       }) {

    categories.length = Math.min(categories.length, 6);

    let showImage = imageList.length > 0;
    let showVideo = videoList.length > 0;
    let mediaContainer = (<div/>);
    if (showImage) {
        try {
            let imageUrl = imageList[0];
            let sizedImageUrl = new URL(imageUrl);
            sizedImageUrl.searchParams.set("width", "252");

            mediaContainer = (
                <Col flex="252px">
                    <div className={styles.banner}>
                        <img alt="banner"
                             src={sizedImageUrl.toString()}
                             className='answerImage'/></div>
                </Col>
            );
        } catch (e) {
            mediaContainer = (<div />);
        }

    } else if (showVideo) {
        mediaContainer = (
            <Col flex="252px">
                <div className={styles.banner}>
                    <video
                        className='show_banner_video'
                        playsInline
                        controls={true}
                        src={videoList[0]}
                    >
                    </video>
                </div>
            </Col>
        );
    }

    let sizedAvatarUrl = new URL(creator.avatar);
    sizedAvatarUrl.searchParams.set("width", "18");

    return (
        <Link to={`/topic/${id}`} className={styles.container}>
            <div className={styles.topicTitle}>{title}</div>
            <Space className={styles.creator} align="center">
                <Avatar url={sizedAvatarUrl}/>
                <div className={styles.nickname}>{creator.nickname}</div>
            </Space>
            <Row className='topicContent' gutter={12}>
                <Col flex="1">
                    {updatedContent}
                </Col>
                {mediaContainer}
            </Row>
            <Space>
                {
                    categories.map((category) => (
                        <Tag className={styles.topicTag} color='#f6f6f6'
                             key={category.id}>#{category.name}</Tag>))
                }
            </Space>
            <Row className={styles.metaInfo}>
                <Col flex={1}>{pv} 浏览· {replies} 个评论· {likes} 个赞同· 0 个收藏</Col>
                <Col>...</Col>
            </Row>
        </Link>
    )
}

export default TopicListItem;
