import {Card} from "antd";
import React from "react";
import styles from './style.module.less'
import {Link} from "react-router-dom";

export default function ({banner: {id, redirectTo, title, image}}) {
    return (
        <Card className={styles.container} key={id}
              cover={<Link to={`/topic/${redirectTo}`}>
                  <img alt={title}
                       src={image}/></Link>}>
        </Card>)
}