import React from 'react';
import ExampleTheme from "./themes/ExampleTheme";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {TableCellNode, TableNode, TableRowNode} from "@lexical/table";
import {ListItemNode, ListNode} from "@lexical/list";
import {CodeHighlightNode, CodeNode} from "@lexical/code";
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {TRANSFORMERS} from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import KnewzieOnChangePlugin from "@/components/Editor/plugins/KnewzieOnChangePlugin";
import ImagesPlugin from "@/components/Editor/plugins/ImagesPlugin";
import VideosPlugin from "@/components/Editor/plugins/VideosPlugin";
import LinkCardPlugin from "@/components/Editor/plugins/LinkCardPlugin";
import {IssuePlugin} from "@/components/Editor/plugins/IssuePlugin";
import {ImageNode} from "@/components/Editor/nodes/ImageNode";
import {VideoNode} from "@/components/Editor/nodes/VideoNode";
import {YouTubeNode} from "@/components/Editor/nodes/YouTubeNode";
import {PodcastNode} from "@/components/Editor/nodes/PodcastNode";
import LinkCardNode from "@/components/Editor/nodes/LinkCardNode";

import styles from './styles.module.less'
import {IssueNode} from "@/components/Editor/nodes/IssueNode";
import {SectionNode} from "@/components/Editor/nodes/SectionNode";
// import WechatPlugin from "@/components/Editor/plugins/WechatPlugin";
import {StyleParagraphNode} from "@/components/Editor/nodes/StyleParagraphNode";

function Placeholder() {
    return <div className="editor-placeholder">请输入内容...</div>;
}

const editorConfig = {
    namespace: "editor",
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        ImageNode,
        VideoNode,
        YouTubeNode,
        PodcastNode,
        LinkCardNode,
        IssueNode,
        SectionNode,
        StyleParagraphNode,
    ]
};

export default function Editor({value = {}, onChange}) {
    return (
        <div className={styles.container}>
            <LexicalComposer initialConfig={editorConfig}>
                <div className="editor-container">
                    <ToolbarPlugin/>
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={<ContentEditable className="editor-input" id="editor-container"/>}
                            placeholder={<Placeholder/>}
                        />
                        <KnewzieOnChangePlugin onChange={onChange}/>
                        <HistoryPlugin/>
                        <ImagesPlugin/>
                        <VideosPlugin/>
                        <LinkCardPlugin/>
                        <AutoFocusPlugin/>
                        <CodeHighlightPlugin/>
                        <ListPlugin/>
                        <LinkPlugin/>
                        <IssuePlugin/>
                        <AutoLinkPlugin/>
                        <ListMaxIndentLevelPlugin maxDepth={7}/>
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS}/>
                    </div>
                </div>
            </LexicalComposer>
        </div>
    );
}
