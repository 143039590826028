import React, {Component} from 'react'
import {Card, Skeleton, Space, Spin} from 'antd';
import InfiniteScroll from "react-infinite-scroll-component";
import axios from 'axios';

import TopicListItem from "@/components/TopicListItem";
import styles from './styles.module.less'
import * as Topic from "@/services/api/Topic";

export default class TopicList extends Component {

    state = {
        topics: [],
        hasMore: true,
        page: 1,
    };

    fetchMoreData = async () => {
        if (this.state.topics.length >= 9999) {
            this.setState({hasMore: false});
            return;
        }
        let {type} = this.props;
        let pageN = this.state.page + 1;
        this.setState({page: pageN});
        const res = await Topic.list(type, pageN);
        const { data: {list} } = res;
        this.setState({
            topics: this.state.topics.concat(list)
        });

    };

    getTopicList = async () => {
        let type = this.props.type;
        const res = await Topic.list(type, 1);
        const { data: {list} } = res;
        this.setState({
            topics: list,
            initialized: true
        });
    }

    componentDidMount() {
        this.getTopicList();
    }

    render() {

        let skeleton = (<>
            <Skeleton active className={styles.skeleton}/>
            <Skeleton active className={styles.skeleton}/>
            <Skeleton active className={styles.skeleton}/>
        </>)


        const {topics, initialized} = this.state;

        return (
            <InfiniteScroll
                dataLength={topics.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={<Spin className="loader" spinning />}
                endMessage={
                    <p style={{textAlign: "center"}}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                <Space direction="vertical" style={{width: "100%"}}>
                    {
                        initialized ? (topics.map((topic) => (
                            <Card key={topic.id}>
                                <TopicListItem topic={topic}/>
                            </Card>
                        ))) : (skeleton)
                    }
                </Space>
            </InfiniteScroll>
        )
    }
}
