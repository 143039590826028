import {Card} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {duration} from "@/utils/TimeUtil";
import styles from './style.module.less'
import Avatar from "../Avatar";
import _ from "lodash";
import LikeButton from "@/components/LikeButton";

function ShowItem({
                      topic: {
                          id, imageList, videoList, title, creator, updatedAt, likes, isLike
                      },
                      width = "auto", height = "auto"
                  }) {

    let showVideo = videoList.length > 0;

    let mediaContainer;

    if (showVideo) {
        mediaContainer = (<video
            className='show_banner_video'
            playsInline
            controls={true}
            src={videoList[0]}
        >
        </video>)
    } else {
        let imageUrl = imageList[0];
        let sizedImageUrl = new URL(imageUrl);
        sizedImageUrl.searchParams.set("width", "190");

        mediaContainer = (
            <img alt="晒一晒图片" src={sizedImageUrl.toString()} className={styles.imageContainer}/>
        );
    }


    let widthProperty = _.isString(width) ? width : `${width}px`;
    let heightProperty = _.isString(width) ? height : `${height}px`;

    let sizedAvatarUrl = new URL(creator.avatar);
    sizedAvatarUrl.searchParams.set("width", "24");

    return (
        <Card className={styles.container}
              style={{width: widthProperty, height: heightProperty}}
              cover={mediaContainer}>
            <Link to={`/show/${id}`} className={styles.titleContainer}>{title}</Link>
            <div className={styles.creator}>
                <Link to="/"><Avatar url={sizedAvatarUrl.toString()}/></Link>
                <div className={styles.text}>
                    <div className={styles.nickname}>{creator.nickname}</div>
                    <div>{duration(updatedAt)}</div>
                </div>
                <LikeButton likes={likes} isLike={isLike} type="topic" topicId={id} />
            </div>
        </Card>
    )
}

export default ShowItem
