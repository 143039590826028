import React, {useEffect} from 'react'
import {Col, Row, Space, Spin} from 'antd';
import {PlusOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom';
import styles from './style.module.less'
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {loadUserInfo} from "@/features/User";

function UserToolbar() {

    const {user, loaded, loading} = useSelector((state) => state.user)
    const dispatch = useDispatch();
    useEffect(() => {
        if (!loaded) {
            dispatch(loadUserInfo());
        }
    }, [])


    return (
        <Row className={styles.settingContainer} style={{alignItems: "center"}} gutter={8}>
            <Col flex={1}>
                <input type="text" name="search" className={styles.inputSearch} placeholder='搜索'></input>
            </Col>
            <Col>
                <Space>
                    <Link to="/topics/create" className={classNames([styles.publish, styles.goToAsk])}>
                        <PlusOutlined/><span className="text">去提问</span>
                    </Link>
                    <Link to="/" className={classNames([styles.publish, styles.goToShow])}>
                        <PlusOutlined/><span className="text">晒一晒</span>
                    </Link>
                </Space>
            </Col>
            <Col>
                <Space size="middle">
                    <Link to="/">
                        <img src={require("@/images/setting.png")} alt="设置"/>
                    </Link>
                    <Link to="/">
                        <img src={require("@/images/message.png")} alt="消息"/>
                    </Link>
                    <Spin spinning={loading}>
                        <Link to="/login">
                            <img src={user ? user.avatar : require('@/images/icon-19.png')}
                                 alt="头像"/>
                        </Link>
                    </Spin>
                </Space>
            </Col>
        </Row>
    )
}

export default UserToolbar;
