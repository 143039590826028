import {
    $createLineBreakNode,
    $createParagraphNode, $isRootNode,
    $isTextNode,
    DOMConversionMap,
    EditorConfig,
    ElementNode,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    RangeSelection,
    SerializedElementNode,
    Spread
} from "lexical";

type SerializedSectionNode = Spread<{
    type: "section",
    style: string,
    version: number,
}, SerializedElementNode>

function convertSectionNode(node: HTMLElement) {
    const {style} = node;
    return { node: $createSectionNode(style.cssText) }
}

export function $createSectionNode(style: string) {
    return new SectionNode(style);
}

export function $isSectionNode(
    node: LexicalNode | null | undefined,
): node is SectionNode {
    return node instanceof SectionNode;
}

export class SectionNode extends ElementNode {
    __style: string;

    static getType(): string {
        return 'section';
    }

    constructor(style: string, key?: NodeKey) {
        super(key);
        this.__style = style;
    }

    static importDOM(): DOMConversionMap | null {
        return {
            section: (node: Node) => ({
                conversion: convertSectionNode,
                priority: 1,
            }),
            br: (node: Node) => {
                const parentElement = node.parentElement;
                // If the <br> is the only child, then skip including it
                if (
                    parentElement != null &&
                    parentElement.firstChild === node &&
                    parentElement.lastChild === node
                ) {
                    return null;
                }
                if (!(node instanceof HTMLElement)) {
                    return null;
                }
                if (node.className == "Apple-interchange-newline") {
                    return ({
                        conversion: () => ({ node: null }),
                        priority: 1,
                    });
                }

                return ({
                    conversion: () => ({ node: $createLineBreakNode() }),
                    priority: 1,
                });
            }
        };
    }

    static clone(node: SectionNode): SectionNode {
        return new SectionNode(node.__style, node.__key);
    }

    exportJSON(): SerializedSectionNode {
        return {
            ...super.exportJSON(),
            style: this.__style,
            type: "section",
            version: 1,
        };
    }

    updateDOM(_prevNode: SectionNode, _dom: HTMLElement, _config: EditorConfig): boolean {
        return false;
    }

    static importJSON(serializedNode: SerializedSectionNode): SectionNode {
        return new SectionNode(serializedNode.style);
    }

    createDOM(_config: EditorConfig, _editor: LexicalEditor): HTMLElement {
        const dom = document.createElement("section");
        dom.style.cssText = this.__style;
        return dom;
    }

    insertNewAfter(selection: RangeSelection): LexicalNode | null {
        const parent = this.getParent();
        if ($isSectionNode(parent)) {
            return parent.insertNewAfter(selection);
        } else {
            return  this.insertAfter($createParagraphNode());
        }
    }

    collapseAtStart(): boolean {
        const children = this.getChildren();
        // If we have an empty (trimmed) first paragraph and try and remove it,
        // delete the paragraph as long as we have another sibling to go to
        if (
            children.length === 0 ||
            ($isTextNode(children[0]) && children[0].getTextContent().trim() === '')
        ) {
            const nextSibling = this.getNextSibling();
            if (nextSibling !== null) {
                this.selectNext();
                this.remove();
                return true;
            }
            const prevSibling = this.getPreviousSibling();
            if (prevSibling !== null) {
                this.selectPrevious();
                this.remove();
                return true;
            }
            const parent = this.getParent();
            if ($isRootNode(parent)) {
                const p = $createParagraphNode();
                parent.append(p);
                p.select();
                this.remove();
            } else {
                this.getParent().select();
                this.remove();
            }
            return true;
        }
        return false;
    }
}
