import React from 'react'
import {Card, Divider, Space} from 'antd';
import {Link} from 'react-router-dom';
import styles from './style.module.less'

function MyBar() {
    return (
        <Card className={styles.container}>
            <Space direction="vertical" style={{width: "100%"}}>
                <Space split={<Divider type="vertical"/>} style={{width: "100%"}}>
                    <Link to="/topics/my/like">
                        <img src={require("@/images/myLove.png")}
                             alt="我的喜欢"/>我的喜欢</Link>
                    <Link to="/topics/my/collection">
                        <img src={require("@/images/myFavorite.png")}
                             alt="我的收藏"/>我的收藏</Link>
                    <Link to="/topics/my/created">
                        <img src={require("@/images/myQuestion.png")}
                             alt="我的问题"/>我的问题</Link>
                </Space>
                <Space split={<Divider type="vertical"/>} style={{width: "100%"}}>
                    <Link to="/topics/my/replied">
                        <img src={require("@/images/myAnswer.png")}
                             alt="我的回答"/>我的回答</Link>
                    <Link to="/topics/my/comment"><img src={require("@/images/myComments.png")}
                                                alt="我的评论"/>我的评论</Link>
                    <Link to="/my/show"><img src={require("@/images/myShow.png")}
                                             alt="我的晒晒"/>我的晒晒</Link>
                </Space>
            </Space>
        </Card>
    )
}

export default MyBar;
