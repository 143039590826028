import axios from 'axios'

export async function sendCaptcha(countryCode, phoneNumber) {
    const request = {
        "platformId": `${countryCode}-${phoneNumber}`,
        "platformType": 1
    };

    const {data} = await axios.post("/user/code", request);
    return data;
}

export async function login(platformType, platformId, password, key = '',) {
    const {data} = await axios.post('/user/login', {
        platformType,
        platformId,
        key, password
    });
    return data;
}

export async function following(uid) {
    const {data} = await axios.post("/user/following", {queryUserId: uid});
    return data;
}

/**
 * 关注该用户
 * @param uid
 * @returns {Promise<void>}
 */
export async function follow(uid) {
    const {data} = await axios.post("/user/follow", {"toUid": uid});
    return data;
}

export async function info(uid) {
    const {data} = await axios.post("/user/info", {queryUserId: uid});
    return data;
}

export async function me() {
    const {data} = await axios.get("/user/me");
    return data;
}
