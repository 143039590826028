import * as React from 'react';
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {$isElementNode, EditorState, LexicalEditor, LexicalNode} from "lexical";
import {$generateHtmlFromNodes} from "@lexical/html";

export default function KnewzieOnChangePlugin({ onChange }) {

    const excludeNode = (nodes) => {
        return nodes.filter(node => {
            const { children, exclude = false } = node;
            if (exclude) {
                return false;
            }

            if (children) {
                node.children = excludeNode(children);
            }
            return true;
        })
    }

    const innerOnChange = (editorState: EditorState, editor: LexicalEditor) => {
        editor.update(() => {
            const html = $generateHtmlFromNodes(editor, null)
            const data = editorState.toJSON();

            const { root } = data;

            root.children = excludeNode(root.children);

            onChange({ editorState: data, html});
        })
    }
    return (
        <OnChangePlugin ignoreSelectionChange={true} onChange={innerOnChange} />
    )
}
