import React from 'react'
import * as ReactDOM from 'react-dom/client';
import {HashRouter, Route, Routes, BrowserRouter} from 'react-router-dom';
import Download from "./pages/Download";
import Login from './pages/Login';
import Home from "./pages/Home";
import reportWebVitals from './reportWebVitals';
import NavigationHeader from "./components/NavigationHeader";
import {Provider} from 'react-redux';
import DocumentTitle from "react-document-title";
import store from './store';
import axios from "axios";

import "./App.less"
import 'nprogress/nprogress.css';

const {hostname} = window.location;

const purePage = hostname === "user.knewzie.com";

if (purePage) {
    axios.defaults.baseURL = "https://api.knewzie.com/"
} else {
    axios.defaults.baseURL = "/api/"
}

const PageRouter = ({children}) => {
    return (purePage ? (<HashRouter>{children}</HashRouter>) : (<BrowserRouter>{children}</BrowserRouter>))
}


const App = () => (
    <Provider store={store}>
        <DocumentTitle title="答知新">
            <PageRouter>
                <Routes>
                    <Route path="/*" element={
                        <>
                            <NavigationHeader/>
                            <Home/>
                        </>
                    }/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/download" element={<Download/>}/>
                </Routes>
            </PageRouter>
        </DocumentTitle>
    </Provider>
)

const root = document.getElementById("root")
if (root && root.innerHTML !== "") {
    ReactDOM.hydrateRoot(root, <App />);
} else {
    ReactDOM.createRoot(root).render(<App/>)
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
