import React, {Component} from 'react'
import {Card, Row} from 'antd';
import {Space, Tag} from 'antd'
import styles from './style.module.less'
import {SyncOutlined} from "@ant-design/icons";

export default class HelpOtherPanel extends Component {
    render() {
        return (
            <Card className={styles.container}>
                <section className={styles.tabContainer}>
                    <span className={styles.tabTitle}>试试帮TA解答</span>
                    <div className="spacer" />
                    <a className={styles.refreshControl} href="#">
                        <SyncOutlined /> <span>点击刷新</span>
                    </a>
                </section>
                <div className={styles.topicTitle}>新西兰多少钱就可以投资移民了？对申请人的英语、年龄有没有要求呀？政策受疫…</div>
                <Row>
                    <div className={styles.topicMetaInfo}>303个回答·1020个点赞</div>
                </Row>
                <div >
                    <Space>
                        <Tag color='#000'>#纽村生活</Tag>
                        <Tag color='#000'>#移民签证</Tag>
                        <Tag color='#000'>#投资移民</Tag>
                    </Space>
                </div>
            </Card>
        )
    }
}
