import React from 'react'
import {Card} from 'antd'
import {Link} from 'react-router-dom'
import AuthorInfo from "../Content/Reply/AuthorInfo";
import UserContentActionToolbar from "../UserContentActionToolbar";
import styles from './styles.module.less'

function ReplyBar({topic, reply, displayList}) {
    return (
        <Card>
            <AuthorInfo replier={reply.replier}/>
            <div className={styles.content}
                 dangerouslySetInnerHTML={{__html: reply.content,}}/>
            <div>
                <div style={{ display: "flex" }}>
                    <UserContentActionToolbar showCollect={false} topic={topic} replyId={reply.id}/>
                    <div className="spacer"/>
                    <div>
                        <div className="answerTopicBarOperateText">...</div>
                    </div>
                </div>
            </div>
            {displayList && (
                <div className="answerTopicBarContentDiv3">
                    {reply.replyList.map((reply, idx) => {
                        return (
                            <>
                                <div>
                                    <div key={reply.id} className={'answerAuthorBarAvator' + idx}>
                                                <span className="sourcehansanscn-medium-black-18px">
                          {reply.replier.nickname}{reply.receiver ? ' 回复了 ' + reply.receiver.nickname : ''} :
                        </span>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: reply.content,
                                            }}
                                        ></span>
                                    </div>
                                    <div className={"answerCommentBarInfoDiv" + idx}>
                                        <div>
                                            <Link to="/">
                                                <img
                                                    alt="like"
                                                    src={require("@/images/like_1.png")}
                                                    className="answerTopicBarLike2"
                                                />
                                            </Link>
                                        </div>
                                        <div className="answerTopicBarLikeText">
                                          <span>
                                            {reply.likes}
                                          </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            )}
        </Card>
    )
}

export default ReplyBar;
