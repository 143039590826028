import React, {Component} from 'react';
import styles from './style.module.less'
import ShowList from "@/layout/ShowList";
import FollowingUserPanel from "../../../components/FollowingUserPanel";
import TagBar from "../../../components/TagBar";
import {Space} from "antd";

function ShowPage() {

    return (
        <Space className={styles.container} direction="vertical">
            <FollowingUserPanel/>
            <TagBar/>
            <ShowList/>
        </Space>
    )
}

export default ShowPage;
