import React, {useEffect, useState} from 'react'
import {Skeleton, Space} from 'antd';
import ShowItem from "@/components/ShowItem";
import styles from './style.module.less'
import * as Topic from "@/services/api/Topic";
import classNames from "classnames";

function ShowCardGallery() {

    const [topics, setTopics] = useState([]);

    const getTopicList = async () => {
        const {data: {list}} = await Topic.list(4, 1);
        setTopics(list);
    }

    useEffect(() => {
        getTopicList();
    }, [])

    const showSkeleton = topics.length === 0;

    const PlaceHolder = () => (
        <div className={styles.skeleton}>
            <Skeleton.Image/>
            <div className={classNames(["ant-skeleton", "ant-skeleton-active", styles.skeletonContent])}>
                <div className="ant-skeleton-content">
                    <ul className="ant-skeleton-paragraph">
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
    )

    return (
        <div className={styles.container}>
            <Space align="stretch">
                {showSkeleton ? (
                    <>
                        <PlaceHolder />
                        <PlaceHolder />
                        <PlaceHolder />
                        <PlaceHolder />
                        <PlaceHolder />
                    </>
                ) : (
                    topics.map((topic) => (
                        <ShowItem topic={topic} width={190} height={250} key={topic.id}/>
                    )))
                }
            </Space>
        </div>
    )
}

export default ShowCardGallery;
