import {HeartFilled, LoadingOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import styles from './style.module.less'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Spin, message} from "antd";
import * as Topic from "@/services/api/Topic";


function LikeButton({isLike, likes, topicId, replyId }) {

    const [isLikedState, setLikedState] = useState(isLike);
    const [likeNumber, setLikeNumber] = useState(likes);
    const [loading, setLoading] = useState(false);

    const {user} = useSelector(state => state.user);
    const navigate = useNavigate();

    const toggleLike = async (e) => {
        e.preventDefault();
        if (!user) {
            navigate("/login");
        } else {
            try {
                setLoading(true);
                const { success, message, data } = await Topic.toggleLike(topicId, replyId);
                if (!success) {
                    throw new Error(message);
                }

                if (isLikedState) {
                    // 如果原来是赞了的话那么就减1
                    setLikeNumber(likeNumber - 1);
                } else {
                    setLikeNumber(likeNumber + 1);
                }
                setLikedState(data);
            } catch (e) {
                message.error(e);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <Spin spinning={loading} indicator={<LoadingOutlined spin />}>
            <a href="#" onClick={toggleLike} className={classNames({
                [styles.actions]: true,
                [styles.isLiked]: isLikedState,
            })}>
                <HeartFilled/>
                <span className={styles.number}>{likeNumber}</span>
            </a>
        </Spin>
    )
}

LikeButton.propTypes = {
    isLiked: PropTypes.bool,
    likes: PropTypes.number,
    type: PropTypes.string,
    topicId: PropTypes.number,
}


export default LikeButton;
