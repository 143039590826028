import {createSlice} from "@reduxjs/toolkit";
import * as Topic from "@/services/api/Topic";
import {KnewzieError} from "@/utils/KnewzieError";

const topicSlice = createSlice({
    name: 'topic',
    initialState: {
        detail: {},
        loading: true,
    },
    reducers: {
        loadedDetail: (state, { payload }) => {
            state.detail = payload;
            state.loading = false;
        },
        resetDetail: (state) => {
            state.detail = {};
            state.loading = true;
        },
        setLoadingState: (state, { payload }) => {
            state.loading = payload;
        }
    }
});

export const { loadedDetail, setLoadingState, resetDetail } = topicSlice.actions;

export const silentFetchTopicDetail = (id) => async (dispatch) => {
    try {
        const res = await Topic.detail(id);
        const {data: topic} = res;
        dispatch(loadedDetail(topic));
    } catch (e) {
        throw e;
    }
}

export const fetchTopicDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingState(true));
            const res = await Topic.detail(id);
            const {success, message, code} = res;
            if (success) {
                const {data: topic} = res;
                dispatch(loadedDetail(topic));
            } else {
                throw new KnewzieError(message, code);
            }
        } finally {
            dispatch(setLoadingState(false));
        }
    };
}

export default topicSlice.reducer;
