import React, {useEffect, useState} from 'react'
import styles from './style.module.less'
import Editor from "@/components/Editor";
import {Button, Form, Input, message, Select} from "antd";
import * as Topic from "@/services/api/Topic"
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Option = Select.Option

function CreateTopicPage() {
    const [categoryList, setCategoryList] = useState([]);

    const {user} = useSelector(state => state.user)

    const navigate = useNavigate();

    const publish = async (data) => {
        const {categories, content: { editorState: contentStruct, html: content } , title} = data;
        if (categories.length > 5) {
            message.warning("类别最多选择5个");
            return;
        }
        try {
            const res = await Topic.create(title, content, JSON.stringify(contentStruct), categories)
            const {success, message: msg} = res;
            if (success) {
                const {data: {topicId}} = res;
                message.success("发布成功");
                navigate(`/topic/${topicId}`, {replace: true});
            } else {
                throw new Error("创建主题失败：" + msg);
            }
        } catch (e) {
            message.error(e.message);
        }
    }

    const fetchCategoryList = async () => {
        try {
            const {data: categoryList} = await Topic.categoryList();
            setCategoryList(categoryList);
        } catch (e) {
            message.error("获取话题分类失败，请刷新重试");
        }
    };

    useEffect(() => {
        if (user === undefined) {
            //未登录
            navigate("/login", {replace: true});
            return;
        }

        fetchCategoryList();
    }, []);

    return (
        <div className={styles.container}>
            <Form onFinish={publish}>
                <Form.Item name="title" rules={[{required: true, message: "必须填写标题"},]}>
                    <Input className={styles.title} placeholder="请输入标题"/>
                </Form.Item>
                <Form.Item name="content" rules={[{required: true, message: "必须填写内容"},]}>
                    <Editor/>
                </Form.Item>
                <Form.Item name="categories"
                           rules={[{required: true, message: "必须选择分类"}, {
                               validator: async (rule, value) => {
                                   if (value && value.length > 5) {
                                       throw new Error("分类不能多余5个")
                                   }
                               }
                           }]}>
                    <Select mode="multiple" placeholder="请选择分类">
                        {categoryList.map((c) => (
                            <Option key={c.id} value={c.id}>{c.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button className={styles.submit} htmlType="submit" type="primary">提交</Button>
            </Form>
        </div>
    )
}

export default CreateTopicPage;
