import React from 'react';
import {Space} from 'antd';
import TopicList from "@/layout/TopicList";
import FollowingUserPanel from "../../components/FollowingUserPanel";
import TagBar from "../../components/TagBar";

function Knowledge() {
    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <FollowingUserPanel/>
            <TagBar/>
            <TopicList type='3'/>
        </Space>
    )
}

export default Knowledge;
