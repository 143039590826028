import {
    $createParagraphNode,
    $isRootNode,
    $isTextNode,
    DOMConversionMap,
    EditorConfig,
    ElementNode,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    SerializedElementNode,
    Spread
} from "lexical";

type SerializedStyleParagraphNode = Spread<{
    type: "styleparagraph",
    style: string,
    version: number,
}, SerializedElementNode>

function convertStyleParagraphNode(node: HTMLParagraphElement) {
    const {style} = node;
    return {node: $createStyleParagraphNode(style.cssText)}
}

export function $createStyleParagraphNode(style: string) {
    return new StyleParagraphNode(style);
}

export function $isStyleParagraphNode(
    node: LexicalNode | null | undefined,
): node is StyleParagraphNode {
    return node instanceof StyleParagraphNode;
}

export class StyleParagraphNode extends ElementNode {
    __style: string;

    static getType(): string {
        return 'styleparagraph';
    }

    constructor(style: string, key?: NodeKey) {
        super(key);
        this.__style = style;
    }

    static importDOM(): DOMConversionMap | null {
        return {
            p: (node: Node) => {

                if (!(node instanceof HTMLParagraphElement)) {
                    return null;
                }
                if (!node.style.cssText) {
                    return null;
                };

                return {
                    conversion: convertStyleParagraphNode,
                    priority: 1,
                }
            },
        };
    }

    static clone(node: StyleParagraphNode): StyleParagraphNode {
        return new StyleParagraphNode(node.__style, node.__key);
    }

    exportJSON(): SerializedStyleParagraphNode {
        return {
            ...super.exportJSON(),
            style: this.__style,
            type: "styleparagraph",
            version: 1,
        };
    }

    updateDOM(_prevNode: StyleParagraphNode, _dom: HTMLElement, _config: EditorConfig): boolean {
        return false;
    }

    static importJSON(serializedNode: SerializedStyleParagraphNode): StyleParagraphNode {
        return new StyleParagraphNode(serializedNode.style);
    }

    createDOM(_config: EditorConfig, _editor: LexicalEditor): HTMLElement {
        const dom = document.createElement("p");
        dom.style.cssText = this.__style;
        return dom;
    }

    collapseAtStart(): boolean {
        const children = this.getChildren();
        // If we have an empty (trimmed) first paragraph and try and remove it,
        // delete the paragraph as long as we have another sibling to go to
        if (
            children.length === 0 ||
            ($isTextNode(children[0]) && children[0].getTextContent().trim() === '')
        ) {
            const nextSibling = this.getNextSibling();
            if (nextSibling !== null) {
                this.selectNext();
                this.remove();
                return true;
            }
            const prevSibling = this.getPreviousSibling();
            if (prevSibling !== null) {
                this.selectPrevious();
                this.remove();
                return true;
            }
            const parent = this.getParent();
            if ($isRootNode(parent)) {
                const p = $createParagraphNode();
                parent.append(p);
                p.select();
                this.remove();
            } else {
                this.getParent().select();
                this.remove();
            }
            return true;
        }
        return false;
    }
}
