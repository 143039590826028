import React, {useEffect, useState} from 'react'
import {Button, Card, Form, Input, message, Select, Spin, Tabs} from 'antd';
import {QqCircleFilled, WechatFilled} from '@ant-design/icons'; //引入antd
import styles from './style.module.less'
import {Link, useNavigate} from "react-router-dom";
import * as User from "@/services/api/User";
import {useDispatch, useSelector} from "react-redux";
import {login} from "@/features/User";

const {TabPane} = Tabs;
const {Option} = Select;

function Login() {

    const navigate = useNavigate();

    const [sendSMS, setSendSMS] = useState(false);

    const {loading, user} = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user) {
            navigate("/", {replace: true})
        }
    }, [user])


    const loginByEmail = async (e) => {
        const {email, password} = e;
        dispatch(login(2, email, password))
    }

    const [phoneForm] = Form.useForm();
    const [timer, setTimer] = useState(0);

    const setupTimer = function () {
        let intervalTimer = 60;
        setTimer(intervalTimer)
        let interval = setInterval(function () {
            if (intervalTimer === 1) {
                clearInterval(interval);
                setTimer(0);
                return;
            }
            setTimer(--intervalTimer);
        }, 1000);
    }

    const sendCaptchaAction = async () => {
        try {
            await phoneForm.validateFields(["phone"])
        } catch (e) {
            return;
        }
        let countryCode = phoneForm.getFieldValue("countryCode");
        let phoneNumber = phoneForm.getFieldValue("phone");

        try {
            setSendSMS(true);

            let res = await User.sendCaptcha(countryCode, phoneNumber);
            if (res.success) {
                message.success("短信已发送")
                setupTimer();
            } else {
                message.error(res.message);
            }

        } catch (e) {
            message.error(`短信发送失败${e}`)
        } finally {
            setSendSMS(false);
        }
    }

    const loginByPhone = async (e) => {
        const {countryCode, phone, captcha} = e;

        const platformId = `${countryCode}-${phone}`
        dispatch(login(1, platformId, '', captcha))
    }

    const mailPanel = (<Form onFinish={loginByEmail}
                             labelCol={{span: 4}}
                             wrapperCol={{span: 20}}>
        <Form.Item
            label="邮箱"
            name="email"
            rules={[{required: true, message: '请输入您的邮箱!'}]}
        >
            <Input/>
        </Form.Item>

        <Form.Item
            label="密码"
            name="password"
            rules={[{required: true, message: '请输入您的密码!'}]}
        >
            <Input.Password/>
        </Form.Item>
        <div className={styles.newUser}>
            <div>没有账号？现在就<Link to="/register">注册</Link></div>
            <div className="spacer"/>
            <Link to="/forgetpwd">忘记密码？</Link>
        </div>
        <Form.Item wrapperCol={{span: 24}}>
            <Spin spinning={loading}>
                <Button type="primary" htmlType="submit" className={styles.loginButton}>
                    登录
                </Button>
            </Spin>
        </Form.Item>
    </Form>)

    const prefixSelector = (
        <Form.Item name="countryCode" noStyle initialValue="64">
            <Select style={{width: 68}}>
                <Option value="64">+64</Option>
                <Option value="86">+86</Option>
            </Select>
        </Form.Item>
    );

    const phonePanel = (<Form onFinish={loginByPhone} form={phoneForm}
                              labelCol={{span: 6}} wrapperCol={{span: 18}}>

        <Form.Item
            label="手机号码"
            name="phone"
            className={styles.phoneField}
            rules={[
                {
                    required: true,
                    message: '请输入您的手机号码!',
                }, {
                    type: "integer",
                    transform: (value) => +(value.trim()),
                    message: '必须输入数字',
                }
            ]}
        >
            <Input addonBefore={prefixSelector}
                   addonAfter={
                       <Spin spinning={sendSMS}>
                           <Button type="primary"
                                   onClick={sendCaptchaAction}
                                   disabled={timer > 0}
                           >{timer > 0 ? timer : "验证码"}</Button>
                       </Spin>
                   }/>
        </Form.Item>

        <Form.Item
            label="验证码"
            name="captcha"
            rules={[{required: true, message: '请输入您的密码!'}]}
        >
            <Input/>
        </Form.Item>
        <div className={styles.newUser}>
            <div>没有账号？现在就<Link to="/register">注册</Link></div>
            <div className="spacer"/>
            <Link to="/forgetpwd">忘记密码？</Link>
        </div>
        <Form.Item wrapperCol={{span: 24}}>
            <Spin spinning={loading}>
                <Button type="primary" htmlType="submit" className={styles.loginButton}>
                    登录
                </Button>
            </Spin>
        </Form.Item>


    </Form>)


    return (
        <div className={styles.background}>
            <div className={styles.container}>
                <a href="/"><img alt="logo" className={styles.logo} src={require("@/images/logo2.png")}/></a>
                <Card className={styles.formContainer}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="邮箱登录" key="1">
                            {mailPanel}
                        </TabPane>
                        <TabPane tab="手机快捷登录" key="2">
                            {phonePanel}
                        </TabPane>
                    </Tabs>
                    <a className={styles.oauth}
                       href="https://open.weixin.qq.com/connect/qrconnect?appid=wx1a1ce41585b9f64f&redirect_uri=https%3A%2F%2Fwww.knewzie.com%2Foauth%2Fwechat%2Fcallback&response_type=code&scope=snsapi_login#wechat_redirect">
                        <WechatFilled className={styles.icon}/><span>微信登录</span>
                    </a>
                </Card>
            </div>
        </div>
    )
}


export default Login;
