import React, { Component } from 'react';
import { Row, Col } from 'antd';

export default class KnewzieHomeContentBar extends Component {
  render() {
    return (
        <div className="homeContentDiv" id="about">
        <div className="homeContentBanner">
          <Row>
              <Col xs={{span: 10, offset: 2 }} md={{span: 10, offset: 2 }} lg={{ span: 10, offset: 2 }} xl={{span: 10, offset: 2 }} xxl={{span: 10, offset: 2 }}>
                  <div className="homeContentBannerLeft">
                      <div className="homeContentImageDiv"><img alt="" src={require("@/images/homepage-06-1@1x.png")} className='homeContentImage'/></div>
                  </div>
              </Col>
              <Col  xs={{span: 12, offset: 0 }} md={{span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} xl={{span: 12, offset: 0 }} xxl={{span: 12, offset: 0 }}>
                  <div className="homeContentBannerRight">
                      <div className='homeContentText'>
                          <span className='homeContentText1'>
                              答知新App是新西兰首个服务新西兰华人的知识社区，通过问答的形式，为每一位华人解惑答疑。
                          </span><br/><br/>
                          <span className='homeContentText2'>
                              这里藏龙卧虎，各种平时你只听过，没见过的行业大咖，将现身江湖，与你分享他们的专业知识，实用经验。<br/><br/>
                              答知新App还是一个跨文化交流、实用知识分享、交友唠嗑阵地、精彩瞬间记录的在线社区。<br/><br/>
                              它更像新西兰华社的【线上居委会】，在这里，每一位华人的声音、意见，都能被看到，被倾听，被点赞，被分享。
                          </span>
                      </div>
                  </div>
              </Col>
          </Row>
        </div>
    </div>
    )
  }
}
