import React from 'react';
import {Space} from 'antd';
import TagBar from "../../components/TagBar";
import ShowCardGallery from "@/components/Home/ShowCardGallery";
import Banner from "@/components/Home/Banner";
import TopicList from "@/layout/TopicList";
import {Route, Routes} from "react-router-dom";
import Following from "../Following";
import ShowPage from "../Show/List";
import Knowledge from "../Knowledge";
import ShowDetailPage from "../Show/Detail";
import Progress from "@/layout/Progress";
import TopicDetailPage from "../Topic/Detail";
import MainLayout from "../../layout/Main";
import UserTopicList from "@/components/UserTopicList";
import CreateTopicPage from "@/pages/Topic/Create";

function Home() {

    const content = (<MainLayout>
        <Routes>
            <Route path="/" element={
                <Progress key="index">
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Banner/>
                        <ShowCardGallery/>
                        <TagBar/>
                        <TopicList type='2'/>
                    </Space>
                </Progress>
            }
            />
            <Route path="/following" element={
                <Progress key="following">
                    <Following/>
                </Progress>
            }/>
            <Route path="/knowledge" element={
                <Progress key="knowledge">
                    <Knowledge/>
                </Progress>
            }/>
            <Route path="/topics/my/like" element={
                <Progress key="myLikeTopics">
                    <UserTopicList type="liked" />
                </Progress>
            }/>
            <Route path="/topics/create" element={
                <Progress key="createTopic">
                    <CreateTopicPage />
                </Progress>
            }/>
            <Route path="/topics/my/replied" element={
                <Progress key="myRepliedTopics">
                    <UserTopicList type="replied" />
                </Progress>
            }/>
            <Route path="/topics/my/collection" element={
                <Progress key="myCollectionTopics">
                    <UserTopicList type="collection" />
                </Progress>
            }/>
            <Route path="/topics/my/history" element={
                <Progress key="myHistoryTopics">
                    <UserTopicList type="history" />
                </Progress>
            }/>
            <Route path="/topics/my/created" element={
                <Progress key="myCreatedTopics">
                    <UserTopicList type="created" />
                </Progress>
            }/>
        </Routes>
    </MainLayout>)

    return (
        <Routes>
            <Route path="/*" element={content}/>
            <Route path="/show" element={
                <Progress key="show">
                    <ShowPage/>
                </Progress>
            }/>
            <Route path="/show/:id" element={
                <Progress key="showDetail">
                    <ShowDetailPage/>
                </Progress>
            }/>
            <Route path="/topic/:id" element={
                <Progress key="topicDetail">
                    <TopicDetailPage />
                </Progress>
            }/>
        </Routes>
    )
}

export default Home;
