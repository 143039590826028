import {configureStore} from '@reduxjs/toolkit'

import HotSearchSlice from "./features/HotSearch";
import UserSlice from './features/User'
import TopicSlice from './features/Topic'

export default configureStore({
    reducer: {
        "hotSearch": HotSearchSlice,
        "user": UserSlice,
        "topic": TopicSlice,
    },
    preloadedState: window.__PRELOADED_STATE__
})
